import React from 'react';
import * as S from './breadcrumbs.style';
import Link from 'next/link';
import { useStore } from '@stores/root';
import { BreadcrumbsSchema } from '@components/schema/breadcrumbs/breadcrumbs-schema';
import { WH } from '@components/WH';

type Props = {
  list: {
    url: string;
    name: string;
  }[];
};

export const Breadcrumbs = (props: Props) => {
  const { geo } = useStore();
  return (
    <WH>
    <S.StyledBreadcrumbs>
      <BreadcrumbsSchema list={props.list} />
      
      <S.BreadCrumbItem key="home">
        
          <Link legacyBehavior prefetch={false} href="/">
            Оренбург
          </Link>
      </S.BreadCrumbItem>
      
      {props.list.map((item, index) => (
        <S.BreadCrumbItem key={index}>
          {index === props.list.length - 1 ? (
            item.name
          ) : (
            <Link legacyBehavior prefetch={false} href={item.url}>
              {item.name}
            </Link>
          )}
        </S.BreadCrumbItem>
      ))}
    </S.StyledBreadcrumbs>
    </WH>
  );
};
